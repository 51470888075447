import React from "react";

const BlogContent = ({ children }) => {

  return (
    <section className="blog-pg single section-padding pt-0">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-md-12">
        <div className="post">
          <div className="content pt-60">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="cont">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </section>
    );
};

export default BlogContent;
