"use client"
import React from "react";
import DarkTheme from "layouts/Dark";
// import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok";
import { graphql } from 'gatsby';
import NavbarLR from "components/NavbarLR/navbar";
import Footer from "components/Footer/footer";
import BlogHeader from "components/Blog-Header/blog-header";
import BlogContent from "components/Blog-Content/blog-content";

const IndexPage = ({ data }) => {
  // let story = data.storyblokEntry
  // story = useStoryblokState(story)

  // const components = story.content.body.map(blok => (<StoryblokComponent blok={blok} key={blok._uid} />))
  const fixedHeader = React.useRef(null);
  const MainContent = React.useRef(null);
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (fixedHeader.current) {
        var slidHeight = fixedHeader.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [fixedHeader, MainContent, navbarRef]);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 150) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 150) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme mobileappstyle={true}>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>

      <NavbarLR nr={navbarRef}  lr={logoRef}/>
      <BlogHeader sliderRef={fixedHeader} title="Local Information" philigram="Local Info" />
      <div ref={MainContent} className="main-content">
        <BlogContent>
          <h3 className="wow extra-title color-font">Welcome To Little River</h3>
          <p>
          Little River, approximately 50 minutes drive from Christchurch on Banks Peninsula, is on State Highway 75 to Akaroa, a major South Island tourist destination. Little River is a great place to visit for walks and mountain biking and is a busy stop over with its Cafe, Store, Restaurant/Bar, Little River Inn, Art Gallery,  Craft Station and Museum.
          </p>
          <p>
          The Christchurch-Little River Rail Trail, a very popular cycling and walking track that opened in 2006, largely follows the course of the old Little River Branch Railway.
          </p>
          <p>
          At Manaia Native Habitat in Okuti Valley you can experience walks through native bush and birds and the beach at Birdlings Flat with its Gemstone and Fossil Museum is a worthwhile stop for fishing and fossicking.
          </p>

          <h3 className="wow extra-title color-font">History</h3>
          <p>
            The population early last century numbered in the thousands, with people mainly employed in timber milling and farming. A major local industry was the harvesting and threshing of cocksfoot. Today the population of the area is approximately 1,700 people, with many people moving into the area for the rural country lifestyle.
          </p>
          <h3 className="wow extra-title color-font">Railway</h3>
          <p>
            The branch line to Little River was built to transport logs from one of the major stands of timber in the Canterbury region. The first 27km to Birdling's Flat opened on 16 May 1882 and  four years later on 11 March 1886, the next 9km opened to Little River. There were proposals to extend the line as far as Akaroa, but these did not eventuate and Little River remained the terminus.
          </p>
          <p>
            Between 1927 and 1934 passengers were served by the experimental and popular Edison battery-electric railcar, the only one of its type to be built. The line closed to passengers on 14 April 1951, and closed to all traffic on 30 June 1962.
          </p>
        </BlogContent>
      </div>
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Little River Music Festival</title>
      {/* <link rel="stylesheet" href="demo.css" /> */}
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`